.navBar {
  background-color: transparent;
  color: #fff !important;
  position: fixed;
  width: 100vw;
  z-index: 10;
  font-weight: bold;
  height: auto;
  overflow: hidden;
  top: 0;
  border-bottom-left-radius: 2em;
  border-bottom-right-radius: 2em;
  transition: all ease 500ms;
  transition-delay: 250ms;
}
.scrollBar {
  overflow: hidden;
  color: #000 !important;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  overflow: hidden;
}
.scrollBar:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: inset 0 0 2000px rgba(255, 255, 255, 0.5);
  filter: blur(10px);
  margin: -20px;
}
.scrolled {
  transform: translate3d(0px, -50px, 0px);
  transition: all ease 500ms;
}
.navBarContainer {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  height: 35px;
  padding: 5px;
  z-index: 1;
  position: relative;
}
#main-title > a {
  display: inline-block;
  font-weight: bold;
  margin: 0 0.5em 0;
  font-size: 1.5em;
  line-height: inherit;
  white-space: nowrap;
  cursor: pointer;
}

.navBar ul {
  flex-direction: row;
  display: flex;
  flex-basis: auto;
  margin-left: auto;
  padding-right: 50px;
}
.navBar ul li {
  display: flex;
  margin-left: 10px;
  margin-right: 10px;
}
#mainMenuList li p {
  cursor: pointer;
}
.navList {
  text-decoration: none;
}

@media (max-width: 650px) {
  #main-title > a {
    font-size: 1em;
  }
  .navBarContainer {
    height: 30px;
    padding: 3px 15px;
  }
}
@media (max-width: 600px) {
  #mainMenuList {
    display: none;
  }
  .menu-icon,
  .menu-icon-animation {
    display: block !important;
  }
}
.menu-icon {
  height: 0px;
  width: 0px;
  margin-right: 20px;
  cursor: pointer;
  display: none;
  transition: all ease 200ms;
}
.menu-icon-animation {
  height: 40px;
  width: 40px;
  display: none;
  transition: all ease 200ms;
}
#indicator {
  background-color: #3f51b5;
  height: 3px;
  margin: 0 0 5px;
  position: absolute;
  transition: all ease-in 100ms;
}
.mobile-nav {
  height: calc(100vh - 136px);
  width: calc(100vw - 4em);
  position: fixed;
  background-color: #000;
  z-index: 10;
  transition: all ease-in 250ms;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 50px 2em;
}
.mobile-nav a {
  font-size: 3em;
  padding: 20px 0;
  color: #fff;
}
.mobile-nav.closed {
  left: -120vw;
}
.mobile-nav.open {
  left: 0;
  color: #fff !important;
}
