$shadow-lg: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
$shadow-md: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

$color-primary: #3f51b5;
$gradient: radial-gradient(circle, #3f51b5, #5f18af);

.button {
    color: #fff;
    border-radius: 1em;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1em;
    overflow: hidden;
    position: relative;
}
.button p {
    margin: 0;
}
.button:focus {
    outline: none;
}
.button:active {
    box-shadow: none;
}
.ripple-animate {
    content: '';
    transition: all linear 100ms;
    background: #fff;
    height: 300px;
    width: 300px;
    position: absolute;
    border-radius: 50%;
    opacity: 0;
}
.ripple {
    content: '';
    background: #fff;
    height: 0px;
    width: 0px;
    position: absolute;
    border-radius: 50%;
    opacity: .7;
}
.sm {
    font-size: .9em;
    width: auto;
    max-width: 150px;
    height: 1.5em;
}
.md {
    font-size: 1.5em;
    width: 200px;
    height: 1.5em;
}
.icon-only {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}
.color {
    background: $color-primary;
    border: none;
    box-shadow: $shadow-md;
}
.white {
    background: none;
    border: solid 2px #fff;
    box-shadow: $shadow-lg;
}
.center {
    margin: 0 auto;
}
.button.md.icon img {
    height: 1.25em;
    width: 1.25em;
    padding: 0 5px 0 0;
}
.secondary {
    color: #000;
    background: #fff;
    border: 1px solid #808080;
    box-shadow: $shadow-md;
}
.fixed {
    position: absolute;
    bottom: 0;
    margin: 1em 2em;
    right: 2em;
}
