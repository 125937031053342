$shadow-lg: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
$shadow-md: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
$neu-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25),
  -2px -2px 5px rgba(255, 255, 255, 0.3);
$gradient: linear-gradient(
  90deg,
  rgba(63, 81, 181, 1) 0%,
  rgba(2, 119, 189, 1) 48%,
  rgba(2, 189, 121, 1) 100%
);
$gradient-2: linear-gradient(90deg, #3f51b5 0%, #5f18af 100%);
$color-main: rgba(2, 119, 189, 1);

#components {
  color: #000;
  height: 600px;
}
//menu
/*timeline bar*/
#components-menu {
  width: 100%;
  display: flex;
  margin: 10px auto 30px;
  max-width: 600px;
  justify-content: space-between;
}
#components-menu img {
  width: 30px;
  padding: 10px 0;
}
.components-menu-inner {
  display: flex;
  overflow-x: scroll;
  align-items: center;
  max-width: 120vw;
}
.components-menu-inner div {
  flex: 0 0 auto;
  padding: 5px;
  margin: 0 10px;
  text-decoration: none;
  color: #000;
  cursor: pointer;
}
.components-menu-inner div p {
  margin: 0px;
  font-size: 20px;
}

.components-menu-inner div.selected {
  flex: 0 0 auto;
  padding: 5px 15px;
  margin-right: 1rem;
  text-decoration: none;
  color: #fff;
  background-color: #3f51b5;
  border-radius: 25px;
}
.components-menu-inner::-webkit-scrollbar {
  display: none;
}
//calendar
.calendar-container {
  display: table;
  margin: 0 auto;
  box-shadow: $shadow-lg;
  max-width: 300px;
  width: 100%;
  max-height: 300px;
  height: 100%;
  border-radius: 1.5em;
  overflow: hidden;
}
.date-select-row {
  margin: 5px auto 0;
  padding: 0.5em;
  border-top: 10px solid $color-main;
  max-width: calc(300px - 1em);
  width: calc(100% - 1em);
  height: auto;
  overflow: hidden;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.week {
  display: flex;
}
.week > div {
  padding: 5px;
}
.day {
  width: calc(100% / 7);
  height: calc(100% / 7);
}
.today {
  background-color: #000;
  color: #fff;
}
.calendar-header {
  color: #fff;
  background: $gradient;
  padding: 5px;
  font-size: 1.5em;
}
.not-month {
  color: lightgrey;
}
input {
  border-radius: 10px;
  border: solid 1px lightgrey;
  padding: 5px;
  margin: 0 0.5em;
}
input:focus {
  outline: none;
}
input.error::placeholder,
.error {
  color: red;
}
.submit-row {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1em auto;
}
ul#notes {
  max-width: 400px;
  width: auto;
  margin: 1em auto;
  overflow-y: scroll;
  max-height: 60vh;
}
ul#notes li {
  display: flex;
  align-items: center;
  list-style-type: none;
  width: 100%;
  animation: notes ease-out 500ms forwards;
}
ul#notes li > span {
  box-shadow: $shadow-md;
  padding: 5px;
  margin: 5px 0;
  width: 80%;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  text-align: left;
}
ul#notes li > span > span:first-child {
  padding: 0 5px;
  color: $color-main;
}
ul#notes li img {
  width: 2em;
  height: 2em;
  margin: 0 5px;
  cursor: pointer;
}

@keyframes notes {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

//converter
.units {
  margin: 0 0.5em;
  height: 2em;
  line-height: calc(2em - 3px);
}
select {
  margin: 0 0.5em 0 0;
  border-radius: 1em;
  border: 1px solid lightgrey;
  width: 6em;
}
.conversion-result {
  margin: 0 1em;
}
//tictactoe
.tictactoe-main p.heading {
  font-size: 2em;
  margin: 0;
  background: $gradient;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: -1em 0 0;
}
.tictactoe-main p.heading span {
  font-weight: bold;
}
.board {
  height: 40vh;
  width: 40vh;
  margin: 1em auto 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.board button {
  flex: 0 calc(40vh / 3);
  height: calc(40vh / 3);
  margin-bottom: 2%; /* (100-32*3)/2 */
  cursor: pointer;
  margin: 0;
  background: transparent;
  border: solid 1px grey;
}
.board button p {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  font-size: 5em;
  color: $color-main;
}
.winner p {
  color: red;
  -webkit-text-fill-color: red !important;
}
.ttt-controller:first-of-type {
  margin: 0 auto 1em;
}
.ttt-controller {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 40vh;
  height: 2em;
  margin: 1em auto 0;
}
.ttt-controller > p {
  color: #505050;
}
.ttt-controller > p > span {
  color: #000;
  font-weight: bold;
  padding-left: 0.5em;
}
//blue strip
.blue-animation {
  background: linear-gradient(90deg, #3f51b5 0%, #5f18af 100%);
  height: 24px;
  width: 70%;
  position: absolute;
  left: -100vw;
  border-bottom-right-radius: 50px;
  top: 70px;
  transition: all ease-in-out 1000ms;
  z-index: 9;
}
.blue-strip {
  left: -1em;
  transition: all ease-in-out 1000ms;
}
