$gradient: linear-gradient(90deg, rgba(63, 81, 181, 1) 0%, rgba(2, 119, 189, 1) 48%, rgba(2, 189, 121, 1) 100%);
$gradient-2: linear-gradient(90deg, #3f51b5 0%, #5f18af 100%);
$color-main: rgba(2, 119, 189, 1);
$white: #f0f8ff;
$shadow-lg: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
$shadow-md: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

.App {
    text-align: center;
    margin-top: 0px;
    background-size: 100%;
    background-repeat: no-repeat;
    background: #f0f8ff;
}
a {
    color: inherit;
    text-decoration: none;
}
#sections {
    height: 100vh;
    max-height: 100dvh;
    overflow: hidden;
    scroll-snap-type: y mandatory;
    overflow-y: scroll;
}
#sections > div {
    scroll-snap-align: center;
    height: 100vh;
    max-height: 100dvh;
}
#sections > div#about,
div#portfolio,
div#components,
div#contact {
    margin: 0 auto;
}
#sections > div:first-of-type {
    scroll-snap-align: center;
    height: 125vh;
}
#sections > div:last-of-type {
    padding: 0;
}
.heading {
    position: relative;
    z-index: 3;
}
.heading h1 {
    z-index: 2;
    color: #282828;
}
.header-title-text {
    z-index: 2;
    color: #282828;
    opacity: 0;
    transform: translateY(-20px) translateX(-10px);
    transition: all ease-in-out 1000ms;
}
.animate {
    opacity: 1;
    transform: translateY(0px) translateX(0px);
}
.heading h2 {
    z-index: 2;
    color: #282828;
}
.heading h2 {
    margin: 0.25em 0 0;
}
@media (max-width: 320px) {
    h3 {
        font-size: 1.5em;
    }
}
.main-container {
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
    padding: 4em 1em 2em;
    height: calc(100vh - 6em);
}
.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}
@media (max-width: 400px) {
    .main-container {
        text-align: center;
        padding: 2em 1em;
        height: calc(100vh - 4em);
    }
    .heading h2 {
        margin: 0.25em 0 0;
    }
}
