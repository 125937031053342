body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  font-size: 16px;
}
h1, h2 {
  font-family: 'Teko', sans-serif;
}
h1 {
  font-size: 4em;
  margin: 0px;
  line-height: 1.5;
}
h2 {
  font-size: 3em;
  margin: 0px;
  font-weight: 300;
}
h3 {
  font-size: 1.75em;
  margin: 0px;
  font-weight: normal;
}
p {
  font-family: 'Montserrat', sans-serif;
  font-size: 1em;
}
a {
  color: inherit;
  text-decoration: none;
}
@media only screen and (max-width: 800px) {
  body {
    font-size: 15px;
  }
}